const encounters = [
  {
    type: "left-a",
    action: "left",
  },
  {
    type: "forward-a",
    action: "forward",
  },
  {
    type: "forward-b",
    action: "forward",
  },
  {
    type: "forward-c",
    action: "forward",
  },
  {
    type: "right-a",
    action: "right",
  },
  {
    type: "bandit",
    action: "attack",
  },
  {
    type: "knight",
    action: "attack",
  },
  {
    type: "rogue",
    action: "attack",
  },
  {
    type: "troll",
    action: "attack",
  },
];

export default encounters;
