import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

function Timer({ factor = 100, initial = 100, step = 0 }) {
  const history = useHistory();
  const [timer, setTimer] = useState(initial - +step);
  useEffect(() => {
    setTimer(initial);
  }, [step, initial]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer <= 1) history.push("/");
      setTimer(timer - 1);
    }, factor);
    return () => clearInterval(interval);
  }, [factor, history, setTimer, timer]);
  return timer;
}

export default Timer;
