export function getDifferentNumber(number, index) {
  return number !== index ? number : index === 1 ? 2 : 1;
}

function getDifferentArrayIndex(array, index) {
  const random = Math.floor(Math.random() * array.length);
  return getDifferentNumber(random, index);
}

export default getDifferentArrayIndex;
