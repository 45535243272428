import { Box, Flex, Button, Container, Paragraph, Title } from "garth";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import getRandomArrayIndex from "./getRandomArrayIndex";
import getDifferentArrayIndex from "./getDifferentArrayIndex";
import ResponsiveGraphic from "./ResponsiveGraphic";
import Timer from "./Timer";

import { ReactComponent as Bandit } from "./assets/bandit.svg";
import { ReactComponent as Knight } from "./assets/knight.svg";
import { ReactComponent as Rogue } from "./assets/rogue.svg";
import { ReactComponent as Troll } from "./assets/troll.svg";

import { ReactComponent as LeftA } from "./assets/left-a.svg";
import { ReactComponent as ForwardA } from "./assets/forward-a.svg";
import { ReactComponent as ForwardB } from "./assets/forward-b.svg";
import { ReactComponent as ForwardC } from "./assets/forward-c.svg";
import { ReactComponent as RightA } from "./assets/right-a.svg";

import encounters from "./encounters";

import { STORAGE_KEY } from "./constants";

const images = {
  "left-a": LeftA,
  "forward-a": ForwardA,
  "forward-b": ForwardB,
  "forward-c": ForwardC,
  "right-a": RightA,
  bandit: Bandit,
  knight: Knight,
  rogue: Rogue,
  troll: Troll,
};

const titles = ["Thud", "Creak", "Bump", "Hiss"];

function Game() {
  const htmlClassList = document.getElementById("html").classList;
  const history = useHistory();
  const [encounter, setEncounter] = useState(getRandomArrayIndex(encounters));
  const [health, setHealth] = useState(3);
  const [title, setTitle] = useState(getRandomArrayIndex(titles));
  const [turn, setTurn] = useState(0);
  const image = encounters[encounter].type;
  const Image = images[image];
  const storage = localStorage.getItem(STORAGE_KEY);
  const score = storage ? storage : "0";
  const handleEncounter = ({ target: { id } }) => {
    if (turn > +score) localStorage.setItem(STORAGE_KEY, turn);
    if (health <= 0) history.push("/");
    if (id === encounters[encounter].action) {
      setEncounter(getDifferentArrayIndex(encounters, encounter));
      setTitle(getDifferentArrayIndex(titles, title));
      setTurn(turn + 1);
    } else {
      if (htmlClassList.contains("initial")) {
        htmlClassList.remove("initial");
        htmlClassList.add("bam");
      }
      htmlClassList.toggle("bam");
      htmlClassList.toggle("boom");
      setHealth(health - 1);
    }
  };
  if (health <= 0) history.push("/");
  return (
    <Container>
      <Box>
        <Title>{titles[title]}</Title>
      </Box>
      <Flex>
        <Box>
          <Paragraph>Health: {health}</Paragraph>
        </Box>
        <Box fluid />
        <Box>
          <Paragraph>
            Timer: <Timer factor={1000} initial={5} step={+turn} />
          </Paragraph>
        </Box>
      </Flex>
      <Box>
        <ResponsiveGraphic>
          <Image className="hover" />
        </ResponsiveGraphic>
      </Box>
      <Box>
        <Button id="attack" onClick={handleEncounter}>
          Drink
        </Button>
      </Box>
      <Flex>
        <Box width="4">
          <Button id="left" onClick={handleEncounter}>
            &lt;
          </Button>
        </Box>
        <Box width="4">
          <Button id="forward" onClick={handleEncounter}>
            ^
          </Button>
        </Box>
        <Box width="4">
          <Button id="right" onClick={handleEncounter}>
            &gt;
          </Button>
        </Box>
      </Flex>
    </Container>
  );
}

export default Game;
