import { Box, Button, Container, Paragraph, Title } from "garth";
import { Link } from "react-router-dom";

function Instructions() {
  return (
    <Container>
      <Box>
        <Title>Instructions</Title>
      </Box>
      <Box>
        <Paragraph>
          Due to some weird circumstances you are trapped in a dungeon.
        </Paragraph>
      </Box>
      <Box>
        <Paragraph>
          Fortunately for you — everyone seems really nice. All they would like
          to do is enjoy a few drinks.
        </Paragraph>
      </Box>
      <Box>
        <Paragraph>
          Try and enjoy as many as you can before you fuck it up or the timer
          runs out.
        </Paragraph>
      </Box>
      <Box>
        <Button element={Link} to="/">
          Okay
        </Button>
      </Box>
    </Container>
  );
}

export default Instructions;
