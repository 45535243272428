import { useEffect } from "react";
import { Box, Button, Container, Paragraph, Title } from "garth";
import { Link } from "react-router-dom";
import ResponsiveGraphic from "./ResponsiveGraphic";
import { ReactComponent as Knight } from "./assets/knight.svg";
import { STORAGE_KEY } from "./constants";
function Home() {
  const htmlClassList = document.getElementById("html").classList;
  const storage = localStorage.getItem(STORAGE_KEY);
  const score = storage ? storage : "0";
  useEffect(() => {
    htmlClassList.remove("bam", "boom");
    htmlClassList.add("initial");
  }, [htmlClassList]);
  return (
    <Container>
      <Box>
        <Title>Dungeon drinker</Title>
      </Box>
      <Box>
        <Paragraph>High score: {score}</Paragraph>
      </Box>
      <Box>
        <ResponsiveGraphic>
          <Knight />
        </ResponsiveGraphic>
      </Box>
      <Box>
        <Button element={Link} to="/game">
          Start
        </Button>
      </Box>
      <Box>
        <Button element={Link} to="/instructions">
          Instructions
        </Button>
      </Box>
      <Box>&copy; Garth</Box>
    </Container>
  );
}

export default Home;
