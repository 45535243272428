import { BrowserRouter, Route, Switch } from "react-router-dom";
import Instructions from "./Instructions";
import Game from "./Game";
import Start from "./Start";
function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Instructions} exact path="/instructions" />
        <Route component={Start} exact path="/" />
        <Route component={Game} exact path="/game" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
